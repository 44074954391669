import ReservePopupBox from '@/page/admin/travel-book/home/component/reserve-popup-box/import_latest_version_module'
import ChoseTraveler from '@/component/choose-traveler/1.0.1/index.vue'
import DomesticTicketAirportSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue'
import consumer_evection_addOrUpdate from '@/lib/data-service/haolv-default/consumer_evection_addOrUpdate'
import consumer_user_historyCity from '@/lib/data-service/haolv-default/consumer_user_historyCity'
import consumer_web_policyConfig_getAllowChangeCities from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getAllowChangeCities'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_apply_bookingJourneyList from '@/lib/data-service/haolv-default/consumer_apply_bookingJourneyList';
import moment from "moment";
import { awaitWrap } from '@/page/admin/company-management/staff-management/common/unit'
import aes_encrypt from "nllib/src/common/aes/aes_encrypt";
import getJourneyOptionsCanBeModified from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
import EmunSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/emun-selector/1.0.0/index.vue'
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info'
import {hideEvectionTypeChangeTabCompanyIdList} from '@/lib/other/index.js';
export default {
    data() {
        return {
            useTravel: 1, // 是否使用出差， 1 是因公出差， 2是因私出行
            showTabBar: false,
            userInfo: {},
            loading:  false,
            activeName: '1',
            evectionRequired: 0,
            bookUpperBound: 0,
            businessTravelList: [],
            unlimitedJourney: 1, // 是否不限行程 0：否 1：是
            allowChangeJourneyTime: 1, // 是否允许修改行程时间 0：否 1：是
            changeEvectionCity: 0,  // 是否可选临近城市 0:否, 1:是
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            isFreeApprove: 0, // 是否免审：1.是，0.否

            flightUserError: false,
            flightFromStation: {},
            flightToStation: {},
            flightType: 1,
            flightDateArr: [],
            flightTime: this.$moment().locale('zh-cn').format('YYYY-MM-DD'),
            flightTravelError: false, // 没使用出差单报错
            flightFormCityError: false,
            flightToCityError: false,
            flightTimeError: false,
            flightPickerOptions: {
                disabledDate: this.flightDisabledDate
            },

            travelCurrent: null, // 出差当前预设

            activeChooseUserList: [], // 选中的员工id

            cityCanEdit: true,
            timeCanEdit: true,
            journeyDetail: {},
        }
    },
    props: {

    },
    components: {
        ReservePopupBox,
        DomesticTicketAirportSelector,
        ChoseTraveler,
        EmunSelector
    },
    created() {
    },
    mounted() {
        this.$refs.formFlightCitySelector.init({
            placeholder_text: `请选择出发城市`,
        });
        this.getUserInfo();
        setTimeout(() => {
            if (this.$route.query.myEvection) {
                let obj = JSON.parse(this.$route.query.myEvection);
                let itemBox = null;
                let journeyBox = null;
                let resultList = this.$refs.reservePopupBox.resultList;
                resultList.forEach((item,index) => {
                    if (item.evectionNo == obj.evectionNo) {
                        itemBox = item;
                        item.journeys.forEach((JItem,JIndex) => {
                            if (JItem.id == obj.id) {
                                journeyBox = JItem;
                            }
                        });
                    }
                });

                if (itemBox && journeyBox) {
                    this.$refs.reservePopupBox.getCurrentItem(itemBox, journeyBox);
                    this.$refs.reservePopupBox.showPop = false;
                }
            }
        },1000);
    },
    async activated() {
        this.getBusinessTravel() // 出差预设
        await this.getEvectionAllInfo() // 获取信息
        this.handleClick()
        this.clearParams()
        this.canChangeFactor()

    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        async useTravel(newVal) {
            await this.canChangeFactor()
        },
        async travelCurrent(newVal) {
            await this.canChangeFactor()
            // if (newVal === null) {
            // } else {
            // }
        },
        flightFromStation(newVal, oldVal) {
            // 当是不限行程，且第一次选了出行城市之后
            if (newVal !== null && oldVal === null && this.travelCurrent.active.journeyType === 1) {
                if (!this.flightToStation) {
                    this.$refs.toFlightCitySelector.__input_click_event_handler();
                } else if (!this.flightTime) {
                    this.$refs.datePicker.focus();
                }

            }
            if (newVal === null) {
                return
            }
            if (newVal.code !== '') {
                this.flightFormCityError = false
            }
        },
        flightToStation(newVal, oldVal) {
            // 当是不限行程，且第一次选了目的城市之后
            if (newVal !== null && oldVal === null && this.travelCurrent.active.journeyType === 1 && this.flightTime === '') {
                this.$refs.datePicker.focus();
            }
            if (newVal === null) {
                return
            }
            if (newVal.code !== '') {
                this.flightToCityError = false
            }
        },
        flightDateArr(newVal, oldVal) {
            if (newVal === null) {
                return
            }
            if (newVal.cityId !== '') {
                this.hotelCityError = false
            }
        },
        flightTime(newVal, oldVal) {
            if (newVal === null) {
                return
            } else {
                this.flightTimeError = false
            }
        },
    },
    computed: {
        showCanNotChangeLeft() {
            let travelCurrent = this.travelCurrent;
            let unlimitedJourney = this.unlimitedJourney;
            return (travelCurrent !== null && unlimitedJourney === 0)
            // return !this.cityCanEdit && this.travelCurrent && (this.changeEvectionCity === 0 || this.journeyDetail.departCityChange === 0)
        },
        showCanNotChangeRight() {
            let travelCurrent = this.travelCurrent;
            let unlimitedJourney = this.unlimitedJourney;
            return (travelCurrent && unlimitedJourney === 0)
            // return !this.cityCanEdit && this.travelCurrent && (this.changeEvectionCity === 0 || this.journeyDetail.toCityChange === 0)
        },
    },
    filters: {},
    methods: {
        async getUserInfo() {
            let res = await get_user_info();
            this.userInfo = res.datas;
            if (hideEvectionTypeChangeTabCompanyIdList.indexOf(this.userInfo.companyId) > -1) {
                this.showTabBar = false;
            } else  {
                this.showTabBar = true;
            }
        },
        // 获取出差预设列表
        getBusinessTravel() {
            let params = {
                businessType: 1
            }
            this.loading = true
            consumer_apply_bookingJourneyList(params).then(res => {
                let businessTravelList = res.datas
                this.businessTravelList = businessTravelList
            }).finally(() => {
                this.loading = false
            })
        },
        async getEvectionAllInfo() {
            await this.refundChangePolicyGet();
            // bug: 15261 【铨成商旅】免审员预订首页出行人要默认选中本人，现在没选中
            if (this.bookUpperBound === 1 || this.isFreeApprove === 1) {
                this.setUserList()
            }
        },
        // 获取出差规则
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get({clear_cache: false});
            console.log('res', res);
            this.evectionRequired = res.datas.evectionRequired;
            this.bookUpperBound = res.datas.bookUpperBound || 6;
            this.unlimitedJourney = res.datas.unlimitedJourney;
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
            this.changeEvectionCity = res.datas.changeEvectionCity;
            this.businessRuleControl = res.datas.businessRuleControl;
            this.isFreeApprove = res.datas.isFreeApprove || 0;
            console.log('unlimitedJourney', this.unlimitedJourney);
        },
        async canChangeFactor() {
            let res = await getJourneyOptionsCanBeModified({
                situation: '100',
                vm: this
            })
            this.cityCanEdit = res.cityCanEdit
            this.timeCanEdit = res.timeCanEdit
        },
        // 改变出差类型时触发
        changeUseTravel(val) {
            this.clearParams()
        },
        clearParams() {
            consumer_user_historyCity().then(res => {
                let data = res.datas
                this.flightFromStation = {
                    name: data && data.flightHistoryInfo && data.flightHistoryInfo.depCityPortName ? data.flightHistoryInfo.depCityPortName : '广州',
                    code: data && data.flightHistoryInfo && data.flightHistoryInfo.depCityPortCode ? data.flightHistoryInfo.depCityPortCode : 'CAN'
                }
                this.flightToStation = {
                    name: data && data.flightHistoryInfo && data.flightHistoryInfo.arrCityPortName ? data.flightHistoryInfo.arrCityPortName : '北京',
                    code: data && data.flightHistoryInfo && data.flightHistoryInfo.arrCityPortCode ? data.flightHistoryInfo.arrCityPortCode : 'BJS'
                }
            }, error => {
                this.flightFromStation = {
                    name: '广州',
                    code: 'CAN'
                }
                this.flightToStation = {
                    name: '北京',
                    code: 'BJS'
                }
            })
            this.travelCurrent = null
            this.flightType = 1
            this.flightDateArr = []
            this.flightTravelError = false
            this.flightFormCityError = false
            this.flightToCityError = false
            this.flightTimeError = false
            // bug: 15261 【铨成商旅】免审员预订首页出行人要默认选中本人，现在没选中
            if ((this.bookUpperBound === 1 && this.useTravel === 1) || this.isFreeApprove === 1) {
                this.$refs.traveler.setUserList()
            }
        },
        chooseJourney(newVal) {
            console.log(newVal);
            const __this = this;
            if (this.travelCurrent === null) {
                this.clearParams()
            } else {
                this.flightTravelError = false
                this.hotelTravelError = false
                this.trainTravelError = false

                // 对时间进行赋值
                const businessType = this.travelCurrent.active.businessType

                this.flightType = this.travelCurrent.active.singleReturnType
                // 机票
                this.flightFromStation = {
                    code: this.travelCurrent.active.departCityId,
                    name: this.travelCurrent.active.departCityName,
                };
                this.flightToStation = {
                    code: this.travelCurrent.active.toCityId,
                    name: this.travelCurrent.active.toCityName,
                };
                // 如果不限行程，则不赋值城市
                if (this.travelCurrent.active.journeyType === 1) {
                    this.flightFromStation = null;
                    this.flightToStation = null;
                }
                if (this.flightType === 1) {
                    if (this.travelCurrent.active.journeyType === 1) {
                        // this.flightTime = this.travelCurrent.active.departTime;
                        // 不限行程的时候，不自动填入出行行程
                        this.flightTime = '';
                    } else {
                        this.flightTime = this.travelCurrent.active.tripDate;
                    }
                    // this.flightTime = this.travelCurrent.active.tripDate || this.travelCurrent.active.departTime;
                } else {
                    this.flightDateArr = [this.travelCurrent.active.tripDate, this.travelCurrent.active.returnTime]
                }

                if (this.changeEvectionCity) {
                    consumer_web_policyConfig_getAllowChangeCities({journeyId: this.travelCurrent.active.id}).then(res => {
                        this.journeyDetail = res.datas
                        if (this.journeyDetail.departCityChange === 1) {
                            this.$nextTick(() => {
                                this.$refs.formEmunSelector.init({
                                    type: `100`,
                                    request_url: null,
                                    entity_define: {
                                        property_name_value: 'value',
                                        property_name_text: 'text',
                                    },
                                    get_data_source() {
                                        const data = {
                                            entity_list: [],
                                        };
                                        let list = __this.journeyDetail.departSelectCities
                                        data.entity_list = list.map(item => {
                                            return {
                                                value: item.cityCode,
                                                text: `${item.cityName}(${item.cityCode})`
                                            }
                                        })
                                        return data;
                                    },
                                    option_all: {
                                        is_show: false,
                                        text: '全部',
                                        value: '',
                                    },
                                })
                            })
                        }
                        if (this.journeyDetail.toCityChange === 1) {
                            this.$nextTick(() => {
                                this.$refs.toEmunSelector.init({
                                    type: `100`,
                                    request_url: null,
                                    entity_define: {
                                        property_name_value: 'value',
                                        property_name_text: 'text',
                                    },
                                    get_data_source() {
                                        const data = {
                                            entity_list: [],
                                        };
                                        let list = __this.journeyDetail.toSelectCities
                                        data.entity_list = list.map(item => {
                                            return {
                                                value: item.cityCode,
                                                text: `${item.cityName}(${item.cityCode})`
                                            }
                                        })
                                        return data;
                                    },
                                    option_all: {
                                        is_show: false,
                                        text: '全部',
                                        value: '',
                                    },
                                })
                            })
                        }
                    })
                }

                // 如果是不限行程，则弹出城市选择
                if (this.travelCurrent.active.journeyType === 1) {
                    // 触发选择出发城市的弹窗方法
                    this.$refs.formFlightCitySelector.__input_click_event_handler();
                }

            }
        },
        // 机票的出发目的地城市调换
        changeFlightCity() {
            if (!this.cityCanEdit) {
                return
            }
            let flightFromStation = JSON.parse(JSON.stringify(this.flightFromStation))
            let flightToStation = JSON.parse(JSON.stringify(this.flightToStation))
            this.flightToStation = flightFromStation
            this.flightFromStation = flightToStation
        },
        // 判断是否能修改
        checkEvectionEdit() {
            if (this.travelCurrent === null) {
                this.$message({
                    type: "warning",
                    message: '必须选择出差单既定行程'
                })
            } else {
                this.$message({
                    type: "warning",
                    message: '不能修改出差单既定行程'
                })
            }
        },
        checkEvectionTimeEdit() {
            this.$message({
                type: "warning",
                message: '不能修改出差单既定行程时间'
            })
        },
        flightDisabledDate(time) {
            const currentTime = time.getTime()
            const nowDate = Date.now() - 8.64e7
            if (this.travelCurrent) {
                // 防旧数据没有这个字段或者这个字段没值
                if (!this.travelCurrent.active.departTime || !this.travelCurrent.active.departTimeEnd) {
                    return (currentTime < nowDate)
                }
                const startDate = this.travelCurrent.active.departTime + ' 00:00:00'
                const endDate = this.travelCurrent.active.departTimeEnd + ' 00:00:00'
                const startDateValue = moment(startDate).valueOf()
                const endDateValue = moment(endDate).valueOf()
                return (currentTime < nowDate) || (currentTime < startDateValue) || (currentTime > endDateValue)
            } else {
                return (currentTime < nowDate)
            }
        },
        async toFlightSearch() {
            if (JSON.stringify(this.flightFromStation) === '{}' || this.flightFromStation === null) {
                this.$message({
                    type: "warning",
                    message: '请选择出发城市'
                })
                return
            }

            if (!this.flightToStation.code) {
                this.$message({
                    type: "warning",
                    message: '请选择目的城市'
                })
                return
            }
            if (!this.flightTime) {
                this.$message({
                    type: "warning",
                    message: '请选择出发日期'
                })
                return
            }
            // 当出行人大于帮订人数
            if (this.useTravel === 1 && this.travelCurrent === null && this.isFreeApprove === 0 && this.activeChooseUserList.length > this.bookUpperBound) {
                this.$message({
                    type: "warning",
                    message: '出行人不能大于帮订人数'
                })
                return
            }
            if (this.isFreeApprove === 1 && this.activeChooseUserList.length > 6) {
                this.$message({
                    type: "warning",
                    message: '出行人不能大于帮订人数'
                })
                return
            }

            let queryParams = {}
            const key = 'params';
            if (this.useTravel === 1) {
                let [checkErr, checkRes] = await awaitWrap(this.$refs.traveler.check())
                if (checkErr) {
                    if (checkErr.type === '001') {
                        this.$message({
                            type: "warning",
                            message: checkErr.msg
                        })
                    }
                    return
                }
            }
            if (this.useTravel === 1) {
                let params = {
                    applyId: this.travelCurrent ? this.travelCurrent.id : '',
                    endDate: this.travelCurrent ? this.travelCurrent.endDate : '',
                    evectionNo: this.travelCurrent ? this.travelCurrent.evectionNo : '',
                    evectionType: this.useTravel === 1 ? 2 : 1,
                    reason: this.travelCurrent ? this.travelCurrent.reason : '',
                    startDate: this.travelCurrent ? this.travelCurrent.startDate : '',
                    travellerList: this.activeChooseUserList,
                    personType: 2
                }
                let res = await consumer_evection_addOrUpdate(params)
                queryParams = {
                    fromStationCode: this.flightFromStation.code,
                    fromStationName: this.flightFromStation.name,
                    toStationCode: this.flightToStation.code,
                    toStationName: this.flightToStation.name,
                    startDate: this.flightType === 2 ? this.flightDateArr[0] : this.flightTime,
                    endDate: this.flightType === 2 ? this.flightDateArr[1] : '',
                    flightType: this.flightType,
                    journeyId: this.travelCurrent ? this.travelCurrent.active.id:0,
                    applyId: this.travelCurrent ? this.travelCurrent.id : '',
                    startRangeDate: this.travelCurrent ? this.travelCurrent.active.departTime : '',
                    endRangeDate: this.travelCurrent ? this.travelCurrent.active.departTimeEnd : '',
                    changeEvectionCity: this.changeEvectionCity,
                    flightIndex: 1,
                }

                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-flight-search',
                    query: {
                        params: encrypted,
                        evectionNo: res.datas,
                        evectionType: this.useTravel === 1 || this.businessRuleControl === 2 ? 2 : 1, // 1是个人出差，2是出差单出差
                        evectionRequired: this.evectionRequired,
                        workTravelType: this.travelCurrent ? 1 : 2, // 因公出差的类型， 1是有选出差单，2是没有
                        journeyType: this.travelCurrent ? this.travelCurrent.active.journeyType : '', // 0 普通单， 1 不限行程单
                    }
                })
            } else {
                queryParams = {
                    fromStationCode: this.flightFromStation.code,
                    fromStationName: this.flightFromStation.name,
                    toStationCode: this.flightToStation.code,
                    toStationName: this.flightToStation.name,
                    startDate: this.flightType === 2 ? this.flightDateArr[0] : this.flightTime,
                    endDate: this.flightType === 2 ? this.flightDateArr[1] : '',
                    flightType: this.flightType,
                    redirectType: '000',
                    flightIndex: 1,
                }
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-flight-search',
                    query: {
                        params: encrypted,
                        evectionNo: '',
                        evectionType: this.useTravel === 1 ? 2 : 1, // 1是个人出差，2是出差单出差
                        evectionRequired: 0,
                    }
                })
            }
        },
        handleClick() {
            this.travelCurrent = null
            /*if (this.useTravel === 1 && this.bookUpperBound === 1 && this.isFreeApprove === 0) {
                this.$refs.traveler.setUserList()
            }*/

            this.flightTravelError = false
            this.flightFormCityError = false
            this.flightToCityError = false
            this.flightTimeError = false
        },
        setUserList() {
            this.$refs.traveler.setUserList()
        },
        getSelectedStaffIdList(selectedStaffIdList) {
            this.activeChooseUserList = selectedStaffIdList;
        },
    }
}
