//import consumer_apply_checkLoginUserPolicy from '@/lib/data-service/haolv-default/consumer_apply_checkLoginUserPolicy'
import ReservePopupBox from '@/page/admin/travel-book/home/component/reserve-popup-box/import_latest_version_module'
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.2.1/index.vue';
import ChoseTraveler from '@/component/choose-traveler/1.0.1/index.vue'
import consumer_evection_addOrUpdate from '@/lib/data-service/haolv-default/consumer_evection_addOrUpdate'
import consumer_trains_account_accountStatus from '@/lib/data-service/haolv-default/consumer_trains_account_accountStatus'
import consumer_user_historyCity from '@/lib/data-service/haolv-default/consumer_user_historyCity'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_apply_bookingJourneyList from '@/lib/data-service/haolv-default/consumer_apply_bookingJourneyList';
import moment from "moment";
import { awaitWrap } from '@/page/admin/company-management/staff-management/common/unit'
import getJourneyOptionsCanBeModified from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
import aes_encrypt from "nllib/src/common/aes/aes_encrypt"; // 新增或修改出差单
import {hideEvectionTypeChangeTabCompanyIdList} from '@/lib/other/index.js';
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
export default {
    data() {
        return {
            cityCanEdit: true,
            timeCanEdit: true,
            useTravel: 1, // 是否使用出差， 1 是因公出差， 2是因私出行
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            showTabBar: false,
            userInfo: {},
            loading:  false,
            activeName: '2',
            evectionRequired: 0,
            bookUpperBound: 0,
            businessTravelList: [],
            unlimitedJourney: 1, // 是否不限行程 0：否 1：是
            allowChangeJourneyTime: 1, // 是否允许修改行程时间 0：否 1：是
            changeEvectionCity: 0,  // 是否可选临近城市 0:否, 1:是
            isFreeApprove: 0, // 是否免审：1.是，0.否

            travelCurrent: null, // 出差当前预设

            fromStation: {},
            toStation: {},
            onlySearch: 0,
            time: this.$moment().locale('zh-cn').format('YYYY-MM-DD'),
            pickerOptions: {
                disabledDate: (time) => {
                    return this.dealDisabledDate(time);
                }
            },
            trainTravelError: false,
            fromStationError: false,
            toStationError: false,
            timeError: false,

            activeChooseUserList: [], // 选中的员工id

            accountStatus: 1, // 1-正常 2-需核验 3-账号/密码变更 4-您还未授权认证12306账号信息，请及时授权认证避免无法购买火车票
            trainDialog: false,
        }
    },
    props: {

    },
    components: {
        ReservePopupBox,
        TrainCitySelector,
        ChoseTraveler,
    },
    created() {
    },
    mounted() {
        this.$refs.formTrainCitySelector.init({
            placeholder_text: `请选择出发城市`,
        });
        this.getUserInfo();
        setTimeout(() => {
            if (this.$route.query.myEvection) {
                let obj = JSON.parse(this.$route.query.myEvection);
                let itemBox = null;
                let journeyBox = null;
                let resultList = this.$refs.reservePopupBox.resultList;
                resultList.forEach((item,index) => {
                    if (item.evectionNo == obj.evectionNo) {
                        itemBox = item;
                        item.journeys.forEach((JItem,JIndex) => {
                            if (JItem.id == obj.id) {
                                journeyBox = JItem;
                            }
                        });
                    }
                });

                if (itemBox && journeyBox) {
                    this.$refs.reservePopupBox.getCurrentItem(itemBox, journeyBox);
                    this.$refs.reservePopupBox.showPop = false;
                }
            }
        },1000);
    },
    async activated() {
        this.getBusinessTravel() // 出差预设
        await this.getEvectionAllInfo() // 获取信息
        this.handleClick()
        this.clearParams();
        this.checkAccount()
        this.canChangeFactorBox();

    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        travelCurrent(newVal) {
            if (newVal === null) {
            } else {
            }
        },
        time(newVal, oldVal) {
            if (newVal === null) {
                return
            } else {
                this.timeError = false
            }
        },
        fromStation(newVal, oldVal) {
            // 当是不限行程，且第一次选了出行城市之后
            if (newVal !== null && oldVal === null && this.travelCurrent.active.journeyType === 1) {
                if (!this.toStation) {
                    this.$refs.toTrainCitySelector.__input_click_event_handler();
                } else if (!this.time) {
                    this.$refs.datePicker.focus();
                }

            }
            if (newVal === null) {
                return
            }
            if (newVal.stationCode !== '') {
                this.fromStationError = false
            }
        },
        toStation(newVal, oldVal) {
            // 当是不限行程，且第一次选了目的城市之后
            if (newVal !== null && oldVal === null && this.travelCurrent.active.journeyType === 1 && this.time === '') {
                this.$refs.datePicker.focus();
            }
            if (newVal === null) {
                return
            }
            if (newVal.stationCode !== '') {
                this.toStationError = false
            }
        },
    },
    computed: {
        canChangeTime(){
            let isOk = true;
            if (this.timeCanEdit) {
                isOk = true
            } else {
                isOk = false
            }
            return isOk
        },
        canChangeFactor() {
            return (this.travelCurrent && this.unlimitedJourney === 0)
            /*let isOk = true;
            if(this.useTravel === 1 && this.evectionRequired === 1) {
                isOk = false;
            }
            if (this.travelCurrent !== null) {
                //判断是否选择出拆单
                isOk = false
            }
            if (this.cityCanEdit) {
                isOk = true
            }
            return isOk*/
        },
        canAddUser() {
            if (this.travelCurrent === null) {
                return this.travelUserList.length < this.bookUpperBound
            } else {
                return true
            }
        },
        canDelUser() {
            if (this.travelCurrent === null) {
                return this.bookUpperBound > 1
            } else {
                return true
            }
        },
    },
    filters: {},
    methods: {
        async getUserInfo() {
            this.loading = true
            let [err, res] = await awaitWrap(get_user_info())
            this.loading = false
            this.userInfo = res.datas
            if (hideEvectionTypeChangeTabCompanyIdList.indexOf(this.userInfo.companyId) > -1) {
                this.showTabBar = false;
            } else  {
                this.showTabBar = true;
            }
        },
        // 获取出差预设列表
        getBusinessTravel() {
            let params = {
                businessType: 2,
            }
            this.loading = true
            consumer_apply_bookingJourneyList(params).then(res => {
                let businessTravelList = res.datas
                this.businessTravelList = businessTravelList
            }).finally(() => {
                this.loading = false
            })
        },
        async getEvectionAllInfo() {
            await this.refundChangePolicyGet();
            //bug: 15261 【铨成商旅】免审员预订首页出行人要默认选中本人，现在没选中
            if (this.bookUpperBound === 1 || this.isFreeApprove === 1) {
                console.log('111222')
                this.setUserList()
            }
        },
        // 获取出差规则
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get({clear_cache: false})
            this.evectionRequired = res.datas.evectionRequired;
            this.bookUpperBound = res.datas.bookUpperBound || 6;
            this.unlimitedJourney = res.datas.unlimitedJourney;
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime
            this.changeEvectionCity = res.datas.changeEvectionCity
            this.businessRuleControl = res.datas.businessRuleControl;
            this.isFreeApprove = res.datas.isFreeApprove || 0;
        },
        checkAccount() {
            console.log('触发接口');
            consumer_trains_account_accountStatus().then(res => {
                // 1-正常 2-需核验 3-账号/密码变更 4-您还未授权认证12306账号信息，请及时授权认证避免无法购买火车票
                const accountStatus = res.datas.accountStatus
                this.accountStatus = accountStatus
                if (this.accountStatus !== 1) {
                    this.trainDialog = true
                } else {
                    this.trainDialog = false;
                }
            })
        },
        dealDisabledDate(time){
            const currentTime = time.getTime();
            const nowDate = Date.now() - 8.64e7;
            if (this.travelCurrent !== null) {
                //有出差单
                const startDate = this.travelCurrent.active.departTime + ' 00:00:00';
                const endDate = this.travelCurrent.active.departTimeEnd + ' 00:00:00';

                const startDateValue = moment(startDate).valueOf();
                const endDateValue = moment(endDate).valueOf();

                return (currentTime < nowDate) || (currentTime < startDateValue) || (currentTime > endDateValue)
            } else {
                //没有出差单
                return (currentTime < nowDate)
            }
        },
        //调用方法判断当前状态下地点、时间是否可以改变
        canChangeFactorBox(){
            getJourneyOptionsCanBeModified({
                situation: '100',
                vm: this
            }).then(res => {
                this.cityCanEdit = res.cityCanEdit;
                this.timeCanEdit = res.timeCanEdit;
            });
        },
        // 改变出差类型时触发
        changeUseTravel(val) {
            this.clearParams();
            this.canChangeFactorBox();
            // if (this.$refs.traveler != undefined) {
            //     console.log(this.$refs.traveler);
            //     this.$refs.traveler.init_switch();
            // }
        },
        async clearParams() {
            await consumer_user_historyCity().then(res => {
                let data = res.datas.trainHistoryInfo;
                this.fromStation = {
                    stationCode: data.fromStationCode,
                    stationName: data.fromStationName
                };
                this.toStation = {
                    stationCode: data.toStationCode,
                    stationName: data.toStationName
                };
            });
            this.onlySearch = 0;
            this.time = this.$moment().locale('zh-cn').format('YYYY-MM-DD')
            this.trainTravelError = false
            this.fromStationError = false
            this.toStationError = false;
            this.timeError = false;
            //bug: 15261 【铨成商旅】免审员预订首页出行人要默认选中本人，现在没选中
            if ((this.bookUpperBound === 1 && this.useTravel === 1) || this.isFreeApprove === 1) {
                this.$refs.traveler.setUserList()
            }
        },
        chooseJourney(newVal) {
            const __this = this;

            this.canChangeFactorBox();

            if (this.travelCurrent === null) {
                this.clearParams()
            } else {
                this.flightTravelError = false
                this.hotelTravelError = false
                this.trainTravelError = false

                // 对时间进行赋值
                const businessType = this.travelCurrent.active.businessType
                // 火车票
                this.fromStation = {
                    stationCode: this.travelCurrent.active.departCityId,
                    stationName: this.travelCurrent.active.departCityName,
                };
                this.toStation = {
                    stationCode: this.travelCurrent.active.toCityId,
                    stationName: this.travelCurrent.active.toCityName,
                };
                this.time = this.travelCurrent.active.tripDate;
                if (this.travelCurrent.active.journeyType == 1) {
                    /*var date1 = new Date(`${moment().format('YYYY-MM-DD')} 00:00:00`).valueOf();
                    var date2 = new Date(`${this.travelCurrent.startDate} 00:00:00`).valueOf();

                    if (date1 > date2) {
                        this.time = moment().format('YYYY-MM-DD');
                    } else {
                        this.time = this.travelCurrent.startDate;
                    }*/

                    // 如果不限行程，则不赋值城市
                    // 不限行程的时候，不自动填入出行行程
                    this.fromStation = null;
                    this.toStation = null;
                    this.time = '';
                    // 如果是不限行程，则弹出城市选择
                    // 触发选择出发城市的弹窗方法
                    this.$refs.formTrainCitySelector.__input_click_event_handler();
                }

            }
        },
        changeCity() {
            let fromStationCode = Object.assign({}, this.toStation)
            let toStationCode = Object.assign({}, this.fromStation)
            if (JSON.stringify(fromStationCode) === '{}') {
                fromStationCode.stationCode = ''
            }
            if (JSON.stringify(toStationCode) === '{}') {
                toStationCode.stationCode = ''
            }
            this.fromStation = fromStationCode;
            this.toStation = toStationCode;
        },
        // 判断是否能修改
        checkEvectionEdit() {
            if (this.useTravel === 1 && this.evectionRequired === 1 && this.travelCurrent === null) {
                this.$message({
                    type: "warning",
                    message: '必须选择出差单既定行程'
                })
            } else {
                if (this.cityCanEdit == true) {
                    return
                }
                this.$message({
                    type: "warning",
                    message: '不能修改出差单既定行程时间'
                })
            }
        },
        async toSearchTrain() {

            // 必填出发站
            if (JSON.stringify(this.fromStation) === '{}' || this.fromStation === null) {
                this.fromStationError = true;
                return
            }
            // 必填到达站
            if (JSON.stringify(this.toStation) === '{}' || this.toStation === null) {
                this.toStationError = true;
                return
            }
            // 必填时间
            if (this.time === '' || this.time === null) {
                this.timeError = true;
                return
            }

            let queryParams = {};
            const key = 'params';

            if (this.useTravel === 1) {
                let params = {
                    applyId: this.travelCurrent ? this.travelCurrent.id : '',
                    endDate: this.travelCurrent ? this.travelCurrent.endDate : '',
                    evectionNo: this.travelCurrent ? this.travelCurrent.evectionNo : '',
                    evectionType: this.useTravel === 1 || this.businessRuleControl === 2 ? 2 : 1, // 1是个人出差，2是出差单出差
                    reason: this.travelCurrent ? this.travelCurrent.reason : '',
                    startDate: this.travelCurrent ? this.travelCurrent.startDate : '',
                    travellerList: this.activeChooseUserList,
                    personType: 2
                }
                let res = await consumer_evection_addOrUpdate(params);
                queryParams = {
                    fromStationCode: this.fromStation.stationCode,
                    toStationCode: this.toStation.stationCode,
                    time: this.time,
                    onlySearch: this.onlySearch,
                    applyId: this.travelCurrent ? this.travelCurrent.id : '',
                    redirectType: '000',
                    journeyId: this.travelCurrent?this.travelCurrent.active.id:0,
                    startRangeDate: this.travelCurrent?this.travelCurrent.active.departTime : '',
                    endRangeDate: this.travelCurrent?this.travelCurrent.active.departTimeEnd : '',
                    activeChooseUserList: this.activeChooseUserList,
                }
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });

                this.$router.push({
                    name: 'admin-train-search',
                    query: {
                        params: encrypted,
                        evectionNo: res.datas,
                        evectionType: this.useTravel === 1 ? 2 : 1, // 1是个人出差，2是出差单出差
                        evectionRequired: this.evectionRequired,    // 出差单是否必填
                        workTravelType: this.travelCurrent ? 1 : 2, // 因公出差的类型， 1是有选出差单，2是没有
                        journeyType: this.travelCurrent == null ? '' : this.travelCurrent.active.journeyType,
                        journeyId: this.travelCurrent ? this.travelCurrent.active.id : 0,
                    }
                })
            } else {
                queryParams = {
                    fromStationCode: this.fromStation.stationCode,
                    toStationCode: this.toStation.stationCode,
                    time: this.time,
                    onlySearch: this.onlySearch,
                    redirectType: '000',
                }
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-train-search',
                    query: {
                        params: encrypted,
                        evectionNo: '',
                        evectionType: this.useTravel === 1 ? 2 : 1, // 1是个人出差，2是出差单出差
                        evectionRequired: 0
                    }
                })
            }
        },
        handleClick() {
            //this.useTravel = 1
            this.travelCurrent = null
            this.travelUserList = []
            /*if (this.useTravel === 1 && this.bookUpperBound === 1 && this.isFreeApprove === 0) {
                this.$refs.traveler.setUserList()
            }*/

            this.trainTravelError = false
            this.fromStationError = false
            this.toStationError = false
            this.timeError = false

        },
        setUserList() {
            this.$refs.traveler.setUserList()
        },
        getSelectedStaffIdList(selectedStaffIdList) {
            console.log('s', selectedStaffIdList)
            this.activeChooseUserList = selectedStaffIdList;
        },
        toEditTrainAccount() {
            if (this.accountStatus === 2) {
                this.$router.push({
                    name: 'admin-train-account-check'
                })
            } else {
                this.$router.push({
                    name: 'admin-train-account-depute'
                })
            }
        },
    }
}
